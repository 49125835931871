import "bootstrap/js/dropdown";
import "bootstrap/js/collapse";
import AOS from "aos";
import "lazysizes";
import ScrollEffects from "./components/scrolling-effects";
import { HomePage } from "./pages/public/homepage";
import CopyButtons from "./components/copy-button";

(function init() {
    ScrollEffects.init();
    HomePage.init();
    CopyButtons.init();
    // Initialise animation library
    AOS.init({
        easing: "ease-in-sine",
        duration: 1000
    });
})();
