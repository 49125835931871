export default class CopyButtons {
    static init() {
        $(".btn-copy").on("click", e => {
            let popup = $(e.target)
                .parents(".copy-wrapper")
                .siblings(".popup")
                .find(".link-copied-popup");
            let length_of_animation = 2000;

            $(e.target)
                .closest(".copy-group")
                .find(".copy-link")
                .select();
            document.execCommand("copy");

            popup.addClass("show-popup");
            setTimeout(() => {
                popup.removeClass("show-popup");
            }, length_of_animation);
        });
    }
}
